<template>
    <v-card outlined elevation="4">
        <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                    {{ valor }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ descricao }} </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="65" :color="cor">
                <v-icon large dark> {{ icone }}</v-icon>
            </v-list-item-avatar>
        </v-list-item>
        <v-card-actions>
            <v-btn text color="orange accent-4" @click="$emit('click', $event)">
                <span v-if="btnVisualizar"> Visualizar </span>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    name: 'CardInfo',
    components: {},
    props: {
        valor: {
            type: Number,
            default: 0
        },
        descricao: {
            type: String,
            required: true
        },
        icone: {
            type: String,
            required: true
        },
        cor: {
            type: String,
            default: 'orange'
        },
        btnVisualizar: {
            type: Boolean,
            default: true
        }
    },
    methods: {}
};
</script>
