<template>
    <v-data-table
        v-model="programacaoCargasSelecionadas"
        :headers="cabecalho"
        :items="programacaoItens"
        :sort-by="['id_programacao_carga_rodopar']"
        :sort-desc="[true]"
        :items-per-page="50"
        :search="programacaoCargaBuscar"
        :fixed-header="true"
        height="60vh"
        item-key="id_programacao_carga"
        loading-text="Buscando, aguarde..."
        class="elevation-5"
        show-select
        multi-sort
        dense
    >
        <template v-slot:top>
            <v-btn
                color="orange"
                @click="abreVinculaModal"
                :disabled="!podeVincular"
                :dark="podeVincular"
                class="mr-2 pa-3 mt-3"
            >
                <v-icon dense left> mdi-truck-check </v-icon>
                Vincular
            </v-btn>

            <v-btn
                color="warning"
                @click="limpaFiltroTabela"
                class="mr-2 pa-3 mt-3"
            >
                <v-icon dense left> mdi-truck-check </v-icon>
                Remove Filtros
            </v-btn>

            <v-row class="pa-2">
                <v-col cols="12" sm="6">
                    <InputSelectV3
                        v-model="filtroUnidadeEmpreendimento"
                        rotulo="Filtro por unidade do solicitante"
                        :menu-props="{ offsetY: true }"
                        :itens="opcaoUnidadeEmpreendimento"
                        itemTexto="codigo"
                        itemValor="codigo"
                        :smallChips="true"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <InputTextV3
                        v-model="programacaoCargaBuscar"
                        iconeAppend="mdi-magnify"
                        rotulo="Buscar..."
                        :contador="200"
                    />
                </v-col>
            </v-row>
        </template>

        <template v-slot:header.id_programacao_carga_rodopar="{ header }">
            {{ header.text }}
            <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon
                            small
                            :color="
                                filtros.id_programacao_carga.length
                                    ? 'primary'
                                    : ''
                            "
                        >
                            mdi-filter
                        </v-icon>
                    </v-btn>
                </template>
                <div style="background-color: white; width: 280px">
                    <InputSelectV3
                        v-model="filtros.id_programacao_carga"
                        :itens="programacaoItens"
                        :smallChips="true"
                        :multiple="true"
                        :rotulo="header.text"
                        estiloCampo="solo"
                        itemTexto="id_programacao_carga_rodopar"
                        itemValor="id_programacao_carga_rodopar"
                        class="pa-4"
                    />
                    <v-btn
                        @click="filtros.id_programacao_carga = []"
                        small
                        text
                        color="primary"
                        class="ml-2 mb-2"
                        >Limpar</v-btn
                    >
                </div>
            </v-menu>
        </template>

        <template v-slot:header.solicitante="{ header }">
            {{ header.text }}
            <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon
                            small
                            :color="filtros.solicitante ? 'primary' : ''"
                        >
                            mdi-filter
                        </v-icon>
                    </v-btn>
                </template>
                <div style="background-color: white; width: 280px">
                    <InputTextV3
                        v-model="filtros.solicitante"
                        :rotulo="header.text"
                        :contador="200"
                        estiloCampo="solo"
                        class="pa-4"
                    />
                    <v-btn
                        @click="filtros.solicitante = ''"
                        small
                        text
                        color="primary"
                        class="ml-2 mb-2"
                        >Limpar</v-btn
                    >
                </div>
            </v-menu>
        </template>

        <template v-slot:header.tipo_operacao="{ header }">
            {{ header.text }}
            <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon
                            small
                            :color="filtros.tipo_operacao ? 'primary' : ''"
                        >
                            mdi-filter
                        </v-icon>
                    </v-btn>
                </template>
                <div style="background-color: white; width: 280px">
                    <InputTextV3
                        v-model="filtros.tipo_operacao"
                        :rotulo="header.text"
                        :contador="200"
                        estiloCampo="solo"
                        class="pa-4"
                    />
                    <v-btn
                        @click="filtros.solicitante = ''"
                        small
                        text
                        color="primary"
                        class="ml-2 mb-2"
                        >Limpar</v-btn
                    >
                </div>
            </v-menu>
        </template>

        <template v-slot:item.acao="{ item }">
            <v-speed-dial
                direction="right"
                :open-on-hover="false"
                transition="slide-y-reverse-transition"
            >
                <template v-slot:activator>
                    <v-btn v-model="fab" color="blue darken-2" dark fab x-small>
                        <v-icon v-if="fab" x-smal> mdi-close </v-icon>
                        <v-icon v-else x-small> mdi-pencil </v-icon>
                    </v-btn>
                </template>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            dark
                            small
                            color="purple lighten-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="editaCampoModal(item, 'observacao_gerais')"
                        >
                            <v-icon>mdi-note-edit-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>OBS: GERAIS</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            dark
                            small
                            color="blue lighten-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="
                                editaCampoModal(item, 'particularidade_cliente')
                            "
                        >
                            <v-icon>mdi-notebook-edit-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>PARTICULARIDADE CLIENTE</span>
                </v-tooltip>
            </v-speed-dial>
        </template>

        <template v-slot:item.valor_mercadoria="{ item }">
            {{ mascaraMoeda(item.valor_mercadoria) }}
        </template>
    </v-data-table>
</template>

<script>
import ApiService from '@/services/ApiService.js';
import { FormataMoeda } from '@/helpers/Funcoes.js';

import moment from 'moment';

import DateTimePickerV2 from '@/components/Form/DateTimePickerV2.vue';
import InputSelectV3 from '@/components/Form/InputSelectV3.vue';
import InputTextV3 from '@/components/Form/InputTextV3.vue';
import VinculaModal from '@/components/AlocacaoCarga/VinculaModal.vue';
import EditaObservacaoModal from '@/components/AlocacaoCarga/EditaObservacaoModal.vue';
import EditaParticularidadeClienteModal from '@/components/AlocacaoCarga/EditaParticularidadeClienteModal.vue';
import CardInfo from '@/components/AlocacaoCarga/CardInfo.vue';
import ModalVeiculosAlocados from '@/components/AlocacaoCarga/ModalVeiculosAlocados.vue';
import ModalTotalCargas from '@/components/AlocacaoCarga/ModalTotalCargas.vue';

export default {
    name: 'AlocacaoCarga',
    components: {
        InputSelectV3,
        InputTextV3,
        DateTimePickerV2,
        VinculaModal,
        CardInfo,
        ModalVeiculosAlocados,
        ModalTotalCargas,
        EditaObservacaoModal,
        EditaParticularidadeClienteModal,
    },
    mounted() {},
    computed: {
        podeVincular() {
            return this.programacaoCargasSelecionadas.length > 0;
        },
        programacaoItens() {
            return this.programacaoCargas.filter((programacaoCarga) => {
                if (!programacaoCarga.motorista && !programacaoCarga.cte) {
                    return programacaoCarga;
                }
            });
        },
    },
    watch: {},
    methods: {
        mascaraMoeda(valor) {
            return FormataMoeda(valor, {}, true);
        },
        editaCampoModal(programacaoCarga, nomeCampo) {
            const modal = `edita_${nomeCampo}_modal`;

            this.$refs[modal].dialog = true;
            this.campoSelecionado = nomeCampo;
            this.programacaoCargaSelecionada = programacaoCarga;
        },
        editaColuna(dados) {
            const prg = this.programacaoCargas.find(
                (prgVei) =>
                    prgVei.id_programacao_carga ===
                    dados.programacaoCarga.id_programacao_carga
            );

            prg[dados.nomeCampo] = dados.campo;
        },
        async vincular(dados) {
            try {
                const registros = this.programacaoCargasSelecionadas.map(
                    function (programacao) {
                        return programacao.id_programacao_carga;
                    }
                );

                // salva no banco de dados
                await this.salvaVincularCarga(registros, dados);

                const pgVeiculos = this.programacaoCargas.map(
                    (programacaoCarga) => {
                        if (
                            !registros.includes(
                                programacaoCarga.id_programacao_carga
                            )
                        ) {
                            return programacaoCarga;
                        }

                        programacaoCarga.motorista =
                            dados.motorista.parceiro.nome;
                        programacaoCarga.id_motorista =
                            dados.motorista.id_motorista;
                        programacaoCarga.veiculo_destino_placa_1 =
                            dados.placa_1.placa;
                        programacaoCarga.veiculo_destino_id_placa_1 =
                            dados.placa_1.id_veiculo;
                        programacaoCarga.veiculo_destino_placa_2 =
                            dados.placa_2.placa;
                        programacaoCarga.veiculo_destino_id_placa_2 =
                            dados.placa_2.id_veiculo;
                        programacaoCarga.ignorar = false;
                        programacaoCarga.frota = dados.placa_1.veiculo_proprio
                            ? true
                            : false;
                        programacaoCarga.perfil = dados.placa_2.frota.nome;

                        return programacaoCarga;
                    }
                );

                this.programacaoCargas = pgVeiculos;

                this.notificacao = {
                    mensagem: `Carga(s) vinculada(s)`,
                    tipo: 'success',
                    mostra: true,
                };
            } catch (e) {
                this.notificacao = {
                    mensagem: e.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        async desvincular(selecionados) {
            try {
                // salva no banco de dados
                await this.salvaDesvincularCarga(selecionados);

                const pgVeiculos = this.programacaoCargas.map(
                    (programacaoCarga) => {
                        if (
                            !selecionados.includes(
                                programacaoCarga.id_programacao_carga
                            )
                        ) {
                            return programacaoCarga;
                        }

                        programacaoCarga.motorista = '';
                        programacaoCarga.veiculo_destino_placa_1 = '';
                        programacaoCarga.veiculo_destino_placa_2 = '';
                        programacaoCarga.ignorar = false;

                        return programacaoCarga;
                    }
                );

                this.programacaoCargas = pgVeiculos;

                this.notificacao = {
                    mensagem: `Carga(s) desvinculada(s)`,
                    tipo: 'success',
                    mostra: true,
                };
            } catch (e) {
                this.notificacao = {
                    mensagem: e.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        async salvaDesvincularCarga(registros) {
            try {
                const dataForm = {
                    dados: {
                        id_programacao_carga: registros, // Array IDS
                    },
                };

                await ApiService({
                    method: 'PUT',
                    url: `V1/alocacao-carga/desvincular-carga`,
                    data: dataForm,
                })
                    .then((resposta) => {})
                    .catch((erro) => {
                        this.notificacao = {
                            mensagem: erro.message,
                            tipo: 'error',
                            mostra: true,
                        };
                    });

                return true;
            } catch (e) {
                throw new Error(e.message);
            }
        },
        async documentoStatus(selecionados, documentoStatus) {
            try {
                // salva no banco de dados
                await this.salvaDocumentoStatus(selecionados, documentoStatus);

                const pgVeiculos = this.programacaoCargas.map(
                    (programacaoCarga) => {
                        if (
                            !selecionados.includes(
                                programacaoCarga.id_programacao_carga
                            )
                        ) {
                            return programacaoCarga;
                        }

                        programacaoCarga.documento_status = documentoStatus;

                        return programacaoCarga;
                    }
                );

                this.programacaoCargas = pgVeiculos;

                let msg = `Documento(s) pronto(s)`;

                if (documentoStatus == 'N') {
                    msg = `Documento(s) em aberto`;
                }

                this.notificacao = {
                    mensagem: msg,
                    tipo: 'success',
                    mostra: true,
                };
            } catch (e) {
                this.notificacao = {
                    mensagem: e.message,
                    tipo: 'error',
                    mostra: true,
                };
            }
        },
        fechouModalEditaColuna() {
            this.campoSelecionado = '';
            this.programacaoCargaSelecionada = {};
            this.programacaoCargasSelecionadas = [];
        },
        ignorar() {
            const pgVeiculos = this.programacaoCargas.map(
                (programacaoCarga) => {
                    if (
                        !this.checkbox.includes(
                            programacaoCarga.id_programacao_carga
                        )
                    ) {
                        return programacaoCarga;
                    }

                    programacaoCarga.motorista = '';
                    programacaoCarga.veiculo_destino_placa_1 = '';
                    programacaoCarga.veiculo_destino_placa_2 = '';
                    programacaoCarga.ignorar = true;
                    return programacaoCarga;
                }
            );

            this.programacaoCargas = pgVeiculos;

            this.checkbox = [];

            this.notificacao = {
                mensagem: `Carga(s) ignorada(s)`,
                tipo: 'success',
                mostra: true,
            };
        },
        async salvaVincularCarga(registros, dados) {
            try {
                const dataForm = {
                    dados: {
                        id_programacao_carga: registros, // Array IDS
                        id_motorista: dados.motorista.id_motorista,
                        id_veiculo_destino_placa_1: dados.placa_1.id_veiculo,
                        id_veiculo_destino_placa_2:
                            dados.placa_2.id_veiculo || null,
                    },
                };

                await ApiService({
                    method: 'post',
                    url: `V1/alocacao-carga/vincular-carga`,
                    data: dataForm,
                })
                    .then((resposta) => {})
                    .catch((erro) => {
                        this.notificacao = {
                            mensagem: erro.message,
                            tipo: 'error',
                            mostra: true,
                        };
                    });

                return true;
            } catch (e) {
                throw new Error(e.message);
            }
        },
        async salvaDocumentoStatus(registros, documentoStatus) {
            try {
                const dataForm = {
                    dados: {
                        id_programacao_carga: registros, // Array IDS
                        documento_status: documentoStatus,
                    },
                };

                await ApiService({
                    method: 'POST',
                    url: `V1/alocacao-carga/documento-status`,
                    data: dataForm,
                })
                    .then((resposta) => {})
                    .catch((erro) => {
                        this.notificacao = {
                            mensagem: erro.message,
                            tipo: 'error',
                            mostra: true,
                        };
                    });

                return true;
            } catch (e) {
                throw new Error(e.message);
            }
        },
        async salvar() {
            try {
                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        await ApiService(urlConfig)
                            .then((resposta) => {
                                this.fechaForm();
                            })
                            .catch((erro) => {
                                this.notificacao = {
                                    mensagem: erro.message,
                                    tipo: 'error',
                                    mostra: true,
                                };
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        abreVinculaModal() {
            this.$refs.vinculaModal.dialog = true;
        },
        limpaFiltroTabela() {
            this.filtros = {
                id_programacao_carga: [],
                solicitante: '',
            };
        },
        async getMotoristas() {
            await ApiService({
                method: 'get',
                url: `V1/motorista/lista?ativo=true&fields[parceiro]=nome`,
                data: {},
            })
                .then((resposta) => {
                    this.motoristas = resposta.data.data.map((motorista) => {
                        const dados = motorista.atributos;
                        dados.id_motorista = motorista.id_registro;
                        return dados;
                    });
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        async getVeiculos() {
            await ApiService({
                method: 'get',
                url: `V1/veiculo/lista?fields[veiculo]=placa,veiculo_proprio&fields[frota]=nome&fields[marca]=nome&fields[modelo]=nome&include=marca,modelo,frota`,
                data: {},
            })
                .then((resposta) => {
                    this.veiculos = resposta.data.data.map((veiculo) => {
                        const dados = veiculo.atributos;
                        dados.id_veiculo = veiculo.id_registro;
                        dados.nomeFormatado =
                            dados.marca.nome +
                            ' - ' +
                            dados.modelo.nome +
                            ' - ' +
                            dados.placa;
                        return dados;
                    });
                })
                .catch((erro) => {
                    this.notificacao = {
                        mensagem: erro.message,
                        tipo: 'error',
                        mostra: true,
                    };
                });
        },
        filtroUnidade(valor) {
            if (!this.filtroUnidadeEmpreendimento) {
                return true;
            }

            return valor === this.filtroUnidadeEmpreendimento;
        },
        filtroIdProgramacaoCarga(valor) {
            if (!this.filtros.id_programacao_carga.length) {
                return true;
            }

            return this.filtros.id_programacao_carga.includes(valor);
        },
        filtroSolicitante(valor) {
            console.log('AQUI', valor, '--', this.filtros.solicitante);
            if (!this.filtros.solicitante) {
                return true;
            }

            return valor.toLowerCase().indexOf(this.filtros.solicitante) !== -1;
        },
    },
    data() {
        return {
            checkbox: [],
            dataDe: moment()
                .subtract(1, 'days')
                .startOf('day')
                .format('YYYY-MM-DD'),
            dataAte: moment().format('YYYY-MM-DD'),
            motoristas: [],
            veiculos: [],
            programacaoCargas: [],
            campoSelecionado: '',
            programacaoCargaSelecionada: {},
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success',
            },
            filial: null,
            opcaoFilial: [],
            opcaoUnidadeEmpreendimento: [],
            regrasFilial: [(v) => !!v || 'Campo é obrigatório'],
            cabecalho: [
                {
                    text: 'AÇÃO',
                    value: 'acao',
                    sortable: false,
                },
                {
                    text: 'ID',
                    value: 'id_programacao_carga',
                    width: '80',
                },
                {
                    text: 'Programação de Carga Rodopar',
                    value: 'id_programacao_carga_rodopar',
                    align: 'start',
                    width: '200',
                    filter: this.filtroIdProgramacaoCarga,
                },
                {
                    text: 'SOLICITANTE',
                    value: 'solicitante',
                    width: '200',
                    filter: this.filtroSolicitante,
                },
                {
                    text: 'TIPO OPERAÇÃO',
                    value: 'tipo_carga',
                    width: '200',
                },
                {
                    text: 'TERMINAL COLETA',
                    value: 'terminal_coleta',
                    width: '200',
                },
                { text: 'MANIFESTO', value: 'manifesto', width: '200' },
                { text: 'CTE', value: 'cte', width: '200' },
                { text: 'OS', value: 'os', width: '200' },
                { text: 'N° DI', value: 'numero_di', width: '200' },
                { text: 'N° DTA', value: 'numero_dta', width: '200' },
                { text: 'REFERÊNCIA', value: 'referencia', width: '200' },
                { text: 'OBS. ENTREGA', value: 'observacao', width: '200' },
                {
                    text: 'OBS. GERAIS',
                    value: 'observacao_gerais',
                    width: '200',
                },
                {
                    text: 'CLIENTE',
                    value: 'nome_tomador_servico',
                    width: '200',
                },
                { text: 'ORIGEM', value: 'terminal_coleta', width: '200' },
                { text: 'DESTINO', value: 'terminal_entrega', width: '200' },
                { text: 'TIPO CARGA', value: 'tipo_carga', width: '200' },
                { text: 'QTD.', value: 'quantidade_itens', width: '200' },
                { text: 'ALTURA', value: 'altura', width: '200' },
                { text: 'LARGURA', value: 'largura', width: '200' },
                { text: 'PROFUNDIDADE', value: 'profundidade', width: '200' },
                { text: 'PESO', value: 'peso_calculado', width: '200' },
                {
                    text: 'VALOR CARGA',
                    value: 'valor_mercadoria',
                    width: '200',
                },
                {
                    text: 'PARCIULARIDADE CLIENTE',
                    value: 'particularidade_cliente',
                    width: '200',
                },
                { text: 'MOTORISTA', value: 'motorista', width: '200' },
                {
                    text: 'PLACA DESTINO',
                    value: 'veiculo_destino_placa_1',
                    width: '200',
                },
                {
                    text: 'REBOQUE',
                    value: 'veiculo_destino_placa_2',
                    width: '200',
                },
                { text: 'PERFIL', value: 'perfil', width: '200' },
                { text: 'FROTA', value: 'frota', width: '200' },
                {
                    text: 'UNIDADE SOLICITANTE',
                    value: 'unidade_solicitante',
                    width: '200',
                    filter: this.filtroUnidade,
                },
            ],
            programacaoCargasSelecionadas: [],
            programacaoCargaBuscar: '',
            fab: false,
            cardTotais: {},
            filtroUnidadeEmpreendimento: null,
            filtros: {
                id_programacao_carga: [],
                solicitante: '',
            },
        };
    },
};
</script>

<style>
.pointer {
    cursor: pointer;
}

.v-data-table__wrapper > table > tbody > tr {
    height: 60px;
}
</style>
